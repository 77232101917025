import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import llave from "./FMXMANTENIMIENTO.png";
import axios from "axios";
import md5 from "md5";
import Cookies from "universal-cookie";
import { Component } from "react";
import { apiUrl } from "../App";


const cookies = new Cookies();

const Login = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [loginAttempts, setLoginAttempts] = useState(0); // Contador de intentos
  const [blockedUntil, setBlockedUntil] = useState(0); // Tiempo en que se levanta el bloqueo

  const handleChange = async (e) => {
    await setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    
  };

  const iniciarSesion = async () => {

    const currentTime = new Date().getTime();

    if (loginAttempts + 1 >= 5) {
      const blockTime = new Date().getTime() + 5 * 60 * 1000; // Bloquear durante 5 minutos
      setBlockedUntil(blockTime);
      setLoginAttempts(0); // Reiniciar el contador de intentos
      alert(`Se ha excedido el número máximo de intentos fallidos. Espere 5 minutos antes de intentar nuevamente.`);
    }

    if (currentTime < blockedUntil) {
    const remainingTime = Math.ceil((blockedUntil - currentTime) / 1000 / 60); // Calcula el tiempo restante en minutos
    alert(`Espere ${remainingTime} minutos antes de intentar iniciar sesión nuevamente.`);
    return;
    }

    if(form.username && form.password){
    const data = {
      Tecnico: form.username,
      Contras: md5(form.password),
    };

    try {
       await axios.get(`${apiUrl}/ordenes/users`, {
        params: data,
      }).then(response=>{
        return response.data;
      }).then(
        response=>{
          if(response.length>0){
            
            var respuesta=response[0];
            cookies.set('Id',respuesta.Id,{path:"/", expires: new Date(Date.now() + 60 * 60 * 1000) })
            cookies.set('Tecnico',respuesta.Tecnico,{path:"/", expires: new Date(Date.now() + 60 * 60 * 1000) })
            alert(`Bienvenido ${respuesta.Tecnico}`)
            window.location.href="./OrdenesLista"
            setLoginAttempts(0);
          }
          else{
            setLoginAttempts(loginAttempts + 1);
            alert('El usuario o la contraseña no son correctos')
            console.log(response[0])
          }
        }
      )
    }catch{
      
    }
    }else{
      alert('Por favor, complete ambos campos antes de iniciar sesión.');
    }
  }

  useEffect(() => {
    // Verificar si la cookie del usuario ya existe
    const tecnicoCookie = cookies.get("Tecnico");
    if (tecnicoCookie) {
      // Si existe, redirigir a la página de "OrdenesLista"
      window.location.href = "./OrdenesLista";
    }
  }, []);

  return (
    <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
      <div
        style={{ display: "flex", justifyContent: "center", height: "30vh" }}
      >
        <img
          src={llave}
          alt="Imagen"
          style={{ maxWidth: "100%", maxHeight: "100%", alignSelf: "center" }}
        />
      </div>

      <form>
        <Box
          bgcolor={"white"}
          display="flex"
          flexDirection={"column"}
          maxWidth={350}
          alignContent={"center"}
          margin="auto"
          marginTop={3}
          padding={3}
          borderRadius={5}
          boxShadow={"5px 5px 10px #a3a3a3"}
          sx={{
            ":hover": {
              boxShadow: "15px 15px 30px #a3a3a3",
            },
          }}
        >
          <Typography
            color={"black"}
            variant="h3"
            padding={3}
            textAlign="center"
          >
            Login
          </Typography>
          <TextField
            margin="normal"
            type="text"
            variant="outlined"
            placeholder="Usuario"
            name="username"
            onChange={handleChange}
          />
          <TextField
            type="password"
            variant="outlined"
            placeholder="Contraseña"
            name="password"
            onChange={handleChange}
          />

          <Button
            endIcon={<LoginIcon />}
            sx={{ marginTop: 3, borderRadius: 3 }}
            variant="contained"
            color="warning"
            onClick={iniciarSesion}
          >
            Iniciar Sesion
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default Login;
