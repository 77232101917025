import React,{useEffect,useState} from "react";
import Cookies from "universal-cookie";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses,Box,Button, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import llave from "./FMX.png";

const cookies= new Cookies();

const OrdenesCerradas=({ordenes})=>{


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 60; // Número de registros por página
  
    // Lógica para calcular el índice del primer y último registro de la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ordenes.slice(indexOfFirstItem, indexOfLastItem);
  
    const numPages = Math.ceil(ordenes.length / itemsPerPage);
    const pageNumbers = Array.from({ length: numPages }, (_, index) => index + 1);

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 13,
          
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const tecnicoCookie = cookies.get('Tecnico');
        if (!tecnicoCookie) {
          // Si no hay información del usuario en las cookies, redirigir al Login
          window.location.href = './';
        }
      }, []);

      const tecnicoCookie=cookies.get('Tecnico');

      const cerrarSesion=()=>{
        cookies.remove('Id',{path:"/"});
        cookies.remove('Tecnico',{path:"/"});
        window.location.href="./"
      }

      const navInicio=()=>{
        window.location.href="./OrdenesLista"
      }
    return(
        <>

        <>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* La imagen estará centrada vertical y horizontalmente */}
        <img
        src={llave}
        alt='Descripción de la imagen'
        sx={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
        />
        </Box>
        </>

         <>
      <Typography variant="h4" mt={2} mb={2} display={"flex"} justifyContent={"center"}>Ordenes Cerradas</Typography>
      <Typography variant="h5" mt={2} mb={1} display={"flex"} justifyContent={"center"}>Bienvenido    {tecnicoCookie}</Typography>
      <Box sx={{backgroundColor:"white",display:"flex",justifyContent:"center"}}>
      <Button variant="contained" color="success" sx={{margin:1}} onClick={navInicio} >Ordenes Abiertas</Button>
      <Button variant="contained" color="error" sx={{margin:1}}onClick={cerrarSesion}>Cerrar Sesion</Button>
     </Box>
     </>
        
        <TableContainer component={Paper}>
        <Table >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Descripción</StyledTableCell>
          </StyledTableRow>
            </TableHead>
            <TableBody>
            {currentItems.map((orden) => {
              if (orden.Estado === 2 && orden.Responsable === tecnicoCookie) {
                return (
                  <StyledTableRow key={orden.idOrden}>
                  <StyledTableCell>{orden.idOrden}</StyledTableCell>
                  <StyledTableCell>{orden.Descripcion_Orden}</StyledTableCell>
        </StyledTableRow>
      );
              }})}
            </TableBody>
        </Table>
        </TableContainer>


       <Box sx={{display: 'flex',justifyContent: 'center', alignItems: 'center',flexWrap: 'wrap'}}>
       <Button variant="outlined" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
        Anterior
        </Button>

        {/* Renderizar los números de página */}
        {pageNumbers.map((pageNumber) => (
          <Button size="small" key={pageNumber} onClick={() => setCurrentPage(pageNumber)}>
            {pageNumber}
          </Button>
        ))}
        <Button variant="outlined" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === numPages}>
          Siguiente
        </Button>
       </Box>
        </>
    );
}
export default OrdenesCerradas;