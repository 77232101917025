import React,{useEffect, useState} from 'react';
import OrdenesLista from './components/OrdenesLista';
import OrdenesCerradas from './components/OrdenesCerradas';
import Login from './components/Login';
import {BrowserRouter,Route,Routes} from 'react-router-dom'

export const apiUrl = process.env.REACT_APP_API_URL;

function App() {
 

  const [ordenes, setOrdenes] = useState([])
  

  useEffect(() => {
   const getOrdenes=()=>{
    fetch(`${apiUrl}/ordenes`)
    .then(res =>res.json())
    .then(res=>setOrdenes(res))
   };
   getOrdenes();

  }, [])

  const [ordenesCerradas, setOrdenesCerradas] = useState([])

  useEffect(() => {
   const getOrdenesCerradas=()=>{
    fetch(`${apiUrl}/cerradas`)
    .then(res =>res.json())
    .then(res=>setOrdenesCerradas(res))
   };
   getOrdenesCerradas();

  }, [])


  return (
    <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<Login/>} />
      <Route exact path='/OrdenesLista' element={<OrdenesLista ordenes={ordenes} setOrdenes={setOrdenes}/>}/>
      <Route exact path='/OrdenesCerradas' element={<OrdenesCerradas ordenes={ordenesCerradas} setOrdenesCerradas={setOrdenesCerradas}/>} />
    </Routes>
    </BrowserRouter> 
    
  );
}

 /* <Navbar titulo='Mantenimiento'/>
      <h1 style={{textAlign:"center"}}>ORDENES MANTENIMIENTO</h1>
      <OrdenesCerradas ordenes={ordenes} setOrdenes={setOrdenes}/>
      */

export default App;
