import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Typography, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import llave from "./FMX.png";
import { tableCellClasses } from '@mui/material';
import { apiUrl } from '../App';

const cookies = new Cookies();

const OrdenesLista = ({ ordenes }) => {
  const [mostrarIniciarPorFila, setMostrarIniciarPorFila] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrdenId, setSelectedOrdenId] = useState(null);
  const [comentario, setComentario] = useState('');

  useEffect(() => {
    const tecnicoCookie = cookies.get('Tecnico');
    if (!tecnicoCookie) {
      window.location.href = './';
    }
  }, []);

  useEffect(() => {
    const initialState = {};
    ordenes.forEach((orden) => {
      initialState[orden.idOrden] = orden.FechaInicioLabores !== null && orden.FechaInicioLabores !== "";
    });
    setMostrarIniciarPorFila(initialState);
  }, [ordenes]);

  const handleIniciar = async (idOrden) => {
    try {
      const FechaInicio = new Date().toISOString();
      const response = await fetch(`${apiUrl}/ordenes/inicio/${idOrden}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Estado: 1, FechaInicioLabores: FechaInicio, Comentario: null }),
      });

      if (response.ok) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error al iniciar la orden:', error);
    }
  };

  const handleCerrar = async () => {
    try {
      console.log(comentario);

      const fechaCierre = new Date().toISOString();
      const response = await fetch(`${apiUrl}/ordenes/${selectedOrdenId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Fecha_cierre: fechaCierre, Estado: 2, comentario: comentario }),
        
        
      });

      if (response.ok) {
        setOpenModal(false);
        window.location.reload();
      } else {
        console.error('Error al cerrar la orden:', response.statusText);
      }
    } catch (error) {
      console.error('Error al cerrar la orden:', error);
    }
  };

  const handleOpenModal = (idOrden) => {
    setSelectedOrdenId(idOrden);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setComentario('');
  };

  const cerrarSesion = () => {
    cookies.remove('Id', { path: "/" });
    cookies.remove('Tecnico', { path: "/" });
    window.location.href = "./";
  };

  const navOc = () => {
    window.location.href = "./OrdenesCerradas";
  };

  const tecnicoCookie = cookies.get('Tecnico');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={llave}
          alt='Descripción de la imagen'
          sx={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
        />
      </Box>
      <Typography variant="h5" mt={2} mb={2} display={"flex"} justifyContent={"center"}>Ordenes de Mantenimiento</Typography>
      <Typography variant="h6" mt={2} mb={1} display={"flex"} justifyContent={"center"}>Bienvenido {tecnicoCookie}</Typography>
      <Box sx={{ backgroundColor: "white", display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="success" sx={{ margin: 1 }} onClick={navOc}>Ordenes Cerradas</Button>
        <Button variant="contained" color="error" sx={{ margin: 1 }} onClick={cerrarSesion}>Cerrar Sesion</Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>ID</StyledTableCell>
              <StyledTableCell align='center'>Descripción</StyledTableCell>
              <StyledTableCell align='center'>Estatus</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordenes.map(
              orden => {
                if (orden.Estado === 1 && orden.Responsable === tecnicoCookie) {
                  return (
                    <StyledTableRow align='center' key={orden.idOrden}>
                      <StyledTableCell align='center'>{orden.idOrden}</StyledTableCell>
                      <StyledTableCell align='center'>{orden.Descripcion_Orden}</StyledTableCell>
                      <StyledTableCell>
                        <>
                          {mostrarIniciarPorFila[orden.idOrden] ? (
                            <button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => handleOpenModal(orden.idOrden)}
                            >
                              Cerrar
                            </button>
                          ) : (
                            <button
                              type='button'
                              className='btn btn-success'
                              onClick={() => handleIniciar(orden.idOrden)}
                            >
                              Iniciar
                            </button>
                          )}
                        </>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                }
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ ...modalStyle }}>
          <Typography variant="h5" component="h2">
            Deja un comentario:
          </Typography>
          <TextField
            label="Comentario"
            multiline
            rows={4}
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            fullWidth
            margin="normal"
          />
                   <button type='button' className='btn btn-success' onClick={handleCerrar} style={{ marginRight: '10px' }}>
            Confirmar Cierre
          </button>
          <button type='button' className='btn btn-danger' onClick={handleCloseModal}>
            Cerrar
          </button>
          
        </Box>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default OrdenesLista;